import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Description as HUIDescription,
  Transition,
  TransitionChild,
} from '@headlessui/react'
import cn from 'classnames'
import React, { Fragment, useEffect, useRef } from 'react'

import { RoodyEmotion } from '~/types/roody-emotion'

export type CenterModalProps = {
  isOpen: boolean
  onClose: () => void
  children: React.ReactNode
  title: string
  type?: 'confirmation' | 'form'
  closeElement?: React.ReactNode
  doneElement: React.ReactNode
  width?: `w-${string}`
  hideShadow?: boolean
  roodyEmotion?: RoodyEmotion | 'off'
}

export default function CenterModal(props: CenterModalProps) {
  const { type = 'confirmation', width, hideShadow } = props

  const calculatedWidth = (() => {
    if (width) return width
    if (type === 'confirmation') return 'w-[498px]'
    if (type === 'form') return 'w-[600px]'
  })()

  const topScrollMarker = useRef<HTMLDivElement>(null)
  const bottomScrollMarker = useRef<HTMLDivElement>(null)

  const handleScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    if (event.currentTarget.scrollTop > 0) {
      topScrollMarker.current?.classList.remove('opacity-0')
    } else {
      topScrollMarker.current?.classList.add('opacity-0')
    }

    if (
      event.currentTarget.scrollTop + event.currentTarget.clientHeight >=
      event.currentTarget.scrollHeight
    ) {
      bottomScrollMarker.current?.classList.add('opacity-0')
    } else {
      bottomScrollMarker.current?.classList.remove('opacity-0')
    }
  }

  useEffect(() => {
    setTimeout(() => {
      const el = document.getElementById('modal-content')!
      if (!el) return
      if (el.scrollTop + el.clientHeight >= el.scrollHeight) {
        bottomScrollMarker.current?.classList.add('opacity-0')
      } else {
        bottomScrollMarker.current?.classList.remove('opacity-0')
      }
    }, 0)
  }, [props.isOpen])

  return (
    <Transition show={props.isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-103"
        onClose={() => {
          // prevent closing the form on click outside or on escape
          if (props.type === 'form') return
          props.onClose()
        }}
      >
        <TransitionChild
          as={Fragment}
          enter="transition-opacity ease-linear duration-250"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-250"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900/80" />
        </TransitionChild>

        <div className="fixed inset-0 z-10">
          <div className="flex items-end justify-center h-full text-center sm:items-center">
            <TransitionChild
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-full sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-full sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel
                className={cn(
                  'relative transform rounded-t-[20px] sm:rounded-b-[20px] bg-white text-left shadow-xl transition-all p-4 sm:p-10 sm:pb-10 pt-4! max-w-full',
                  props.roodyEmotion !== 'off' && 'mt-16',
                  calculatedWidth
                )}
              >
                {props.roodyEmotion === 'off' ? null : (
                  <div className="hidden sm:block absolute left-0 right-0 -top-20">
                    <div className="flex justify-center">
                      <img
                        src={props.roodyEmotion || RoodyEmotion.COOL}
                        alt="Roody"
                        width="160"
                        height="160"
                      />
                    </div>
                  </div>
                )}

                {/* <div className="sm:hidden py-1 flex items-center justify-center select-none outline outline-red-500">
                  <div className="h-2 bg-gray-300 rounded-full w-[50px]" />
                </div> */}

                <div className={cn('pt-4 sm:pt-16', props.roodyEmotion === 'off' && 'sm:pt-6')}>
                  <DialogTitle className="px-2 sm:text-center text-xl font-medium text-gray-600 mb-3 sm:mb-6">
                    {props.title}
                  </DialogTitle>

                  <div
                    id="modal-content"
                    onScroll={handleScroll}
                    className="max-h-[calc(100vh-380px)] relative overflow-y-auto rounded-sm"
                    aria-label={`${props.title} content`}
                  >
                    <div
                      ref={topScrollMarker}
                      className="sticky top-0 h-4 w-full bg-linear-to-b from-gray-100 transition-opacity rounded-t opacity-0"
                    />
                    <div className="px-2">{props.children}</div>
                    {!hideShadow && (
                      <div
                        ref={bottomScrollMarker}
                        className="sticky bottom-0 h-4 w-full bg-linear-to-t from-gray-100 transition-opacity rounded-b opacity-0"
                      />
                    )}
                  </div>

                  <footer
                    className="px-2 grid gap-3 grid-cols-1 col-span-1 sm:flex mt-3 sm:mt-6 justify-between mb-[env(safe-area-inset-bottom)] sm:mb-0"
                    aria-label="Modal actions"
                  >
                    <div className="order-last sm:order-none [&>button]:w-full">
                      {props.closeElement}
                    </div>
                    <div className="[&>button]:w-full">{props.doneElement}</div>
                  </footer>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

function Description(
  props: React.JSX.IntrinsicElements['p'] & { ref?: React.Ref<HTMLParagraphElement> }
) {
  const classes = cn('text-app-gray-dark font-medium', props.className)
  return <HUIDescription as="p" {...props} className={classes} />
}

CenterModal.Description = Description
